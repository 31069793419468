<template>
  <div :style="pos">
    <span></span>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  props: ["top", "right", "bottom", "left"],
  computed: {
    pos() {
      return `
        --top:${this.top ? this.top : ""}; --right:${
        this.right ? this.right : ""
      }; --bottom:${this.bottom ? this.bottom : ""}; --left:${
        this.left ? this.left : ""
      };
      `;
    },
  },
};
</script>

<style scoped lang='scss'>
div {
  /* backdrop-filter: blur(5px); */
  opacity: 0.4;
  position: absolute;
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  span {
    display: inline-block;
    height: 500px;
    width: 500px;
    background-image: radial-gradient(
      50% 50% at 50% 50%,
      rgba(1, 191, 255, 0.27) 0%,
      rgba(4, 4, 43, 0) 100%
    );
    background-image: -webkit-radial-gradient(
      50% 50% at 50% 50%,
      rgba(1, 191, 255, 0.27) 0%,
      rgba(4, 4, 43, 0) 100%
    );
  }
}
</style>