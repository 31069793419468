<template>
  <TransitionElement>
    <div>
      <transition appear @enter="enter">
        <section class="hero nopad" ref="hro">
          <div class="container">
            <div class="spbu">
              <div class="cont">
                <h1>We make your dream come true</h1>
                <p>
                  We are aimed at giving you the best solution to all of your
                  problems. We deliver to you the best products to fit into your
                  needs at the right time. We help you to grow, learn more and
                  make progress. We are here to give you the best services you
                  deserve.
                </p>
              </div>
              <div>
                <div class="msvg">
                  <svg
                    :width="isDesktop ? 519 : 207"
                    :height="isDesktop ? 330 : 336"
                    :viewBox="isDesktop ? '0 0 519 330' : '0 0 207 336'"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      v-if="isDesktop"
                      d="M1.5 42.3373C12.4894 -25.5 154.353 -7.35558 130.875 85C25.5 499.5 517.5 -213.51 517.5 329"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-dasharray="10 10"
                      ref="path1"
                    />
                    <path
                      ref="path1"
                      d="M171.736 0.999999C312 253 -36.0001 21.5 4.49941 335"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-dasharray="10 10"
                      v-else
                    />
                    <g ref="plane1cont">
                      <g ref="plane1" clip-path="url(#clip0_156_109)">
                        <path
                          d="M4.40637 29.4064L16.4064 4.40637L29.4064 29.4064L16.4064 26.4064L4.40637 29.4064ZM16.4064 25.4064V18.4064Z"
                          fill="#01BFFF"
                        />
                        <path
                          d="M16.4064 25.4064V18.4064M4.40637 29.4064L16.4064 4.40637L29.4064 29.4064L16.4064 26.4064L4.40637 29.4064Z"
                          stroke="#1277DD"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="spb">
              <div><img src="@/assets/images/hashake.svg" alt="" /></div>
              <div class="contt">
                <h1>
                  <!-- We’re proud to be the world’s largest specialist brand and
                  design group. -->
                  We're proud of our team of diligent and passionate experts
                </h1>
                <p>
                  Our team consists of young professional tech experts who are
                  passionate about their work. They focus on bringing in the
                  latest and newest technologies to solve problems
                </p>
              </div>
            </div>
            <div class="w-100 cen">
              <svg
                :width="isDesktop ? 450 : 207"
                :height="isDesktop ? 200 : 336"
                :viewBox="isDesktop ? '0 0 450 200' : '0 0 207 336'"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  ref="path2"
                  d="M395.853 0.999983C629.15 488.031 19.9429 -259.714 1.00001 198.903"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-dasharray="10 10"
                  v-if="isDesktop"
                />
                <path
                  v-else
                  ref="path2"
                  d="M34.5098 0.999999C-105.754 253 242.246 21.5 201.747 335"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-dasharray="10 10"
                />
                <g ref="plane2cont">
                  <g ref="plane2" clip-path="url(#clip0_156_109)">
                    <path
                      d="M4.40637 29.4064L16.4064 4.40637L29.4064 29.4064L16.4064 26.4064L4.40637 29.4064ZM16.4064 25.4064V18.4064Z"
                      fill="#01BFFF"
                    />
                    <path
                      d="M16.4064 25.4064V18.4064M4.40637 29.4064L16.4064 4.40637L29.4064 29.4064L16.4064 26.4064L4.40637 29.4064Z"
                      stroke="#1277DD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div class="blbg">
              <back-gradient top="0" right="-200px" />
              <back-gradient top="439px" left="-200px" />
              <div class="w-100 spbu">
                <div class="cont">
                  <h1>
                    We design and build websites, apps, and digital experiences
                    for nice people with interesting problems.
                  </h1>
                  <p>
                    Our services are not limited to building websites and apps,
                    we create a new and different experience for our clients. We
                    give them the reason to keep coming back.
                  </p>
                </div>
              </div>
              <div class="w-100">
                <div class="ft">
                  <div>
                    <h1 class="w-100">Featured Work</h1>
                  </div>
                  <svg
                    :width="isDesktop ? 588 : 218"
                    :height="isDesktop ? 439 : 407"
                    :viewBox="isDesktop ? '0 0 588 439' : '0 0 218 407'"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M80.4997 0.999978C-142.184 465.876 434 -29 574 218.5C684.646 414.108 24.5001 436.5 1.00004 437.5"
                      v-if="isDesktop"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-dasharray="10 10"
                      ref="path3"
                    />
                    <path
                      v-else
                      d="M29.5002 1.5C-124.5 59.5 417.5 282 132 405.5"
                      stroke="white"
                      ref="path3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-dasharray="10 10"
                    />

                    <g ref="plane3cont">
                      <g ref="plane3" clip-path="url(#clip0_156_109)">
                        <path
                          d="M4.40637 29.4064L16.4064 4.40637L29.4064 29.4064L16.4064 26.4064L4.40637 29.4064ZM16.4064 25.4064V18.4064Z"
                          fill="#01BFFF"
                        />
                        <path
                          d="M16.4064 25.4064V18.4064M4.40637 29.4064L16.4064 4.40637L29.4064 29.4064L16.4064 26.4064L4.40637 29.4064Z"
                          stroke="#1277DD"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </section>
      </transition>
    </div>
  </TransitionElement>
  <!-- <section class="re nopad" v-if="isDesktop">
    <div class="hor" ref="hor">
      <div class="panel" ref="panel">
        <img src="@/assets/images/smatr.png" alt="" />
      </div>
      <div class="panel" ref="panel2">
        <img src="@/assets/images/paycelerf.png" alt="" />
      </div>
      <div class="panel" ref="panel3">
        <img src="@/assets/images/secnetf.png" alt="" />
      </div>
      <div class="panel" ref="panel4">
        <img src="@/assets/images/unkn.png" alt="" />
      </div>
    </div>
  </section> -->
  <section class="re sec2 nopad">
    <div class="container" @scroll="horScrol">
      <div class="hor" ref="scr">
        <div class="panel">
          <img src="@/assets/images/scrsmatr.png" alt="" />
        </div>
        <div class="panel">
          <img src="@/assets/images/scrpayceler.png" alt="" />
        </div>
        <div class="panel">
          <img src="@/assets/images/scrsecnet.png" alt="" />
        </div>
        <div class="panel">
          <img src="@/assets/images/scrunkn.png" alt="" />
        </div>
      </div>
    </div>
  </section>
  <TransitionElement>
    <div class="w-100 mobh">
      <section class="srv">
        <div class="container">
          <back-gradient top="-300px" right="-100px" />
          <back-gradient bottom="-300px" left="-100px" />
          <div class="row align-items-center">
            <div class="col-md-6">
              <span>The things we do:</span>
              <h1>We build more than just experiences</h1>
              <p>
                TeliyTech is a design and software development studio. We
                deliver everything you need to launch or relaunch your brand—
                including the boring stuff. Launching a brand is more than just
                designing a logo, business cards, and a website. We do all that,
                plus everything else you’ll need to deliver your freshly minted
                brand. We’re not just another graphic design studio, we’re a
                full brand we’re a full brand and business-building machine.
              </p>
              <button v-if="isDesktop">Start A Project</button>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <h3>Visual Branding</h3>
              <h3>Website Development</h3>
              <h3>App Development</h3>
              <h3>Product Design</h3>
              <h3>Training Program</h3>
              <h3>IT Technical Support</h3>
              <h3>Data Analysis.</h3>
              <h3>ML and AI development.</h3>
              <button v-if="!isDesktop">Start A Project</button>
            </div>
          </div>
        </div>
      </section>
      <section class="m2pro">
        <div class="container">
          <div
            id="m2Carousel"
            class="carousel slide"
            data-ride="carousel"
            data-interval="10000"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-target="#m2Carousel"
                data-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              >
                <span></span>
              </button>
              <button
                type="button"
                data-target="#m2Carousel"
                data-slide-to="1"
                aria-label="Slide 2"
              >
                <span></span>
              </button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <div>
                        <h1>Mission2Pro Training</h1>
                        <p>
                          MissionToPro is a program designed for adults who are
                          new to IT or want to advance their knowledge. The
                          program offers courses and training on various IT
                          skills, from basic computer skills to advanced
                          programming. Participants can gain practical knowledge
                          of the latest technologies and become proficient in
                          different IT areas. It's an excellent opportunity for
                          those who want to pursue a career in IT or improve
                          their skills.t designing a logo, business cards, and a
                          website.
                        </p>
                      </div>
                      <div class="tgs">
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Software Development</p>
                        </div>
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Product(UIUX) Designs</p>
                        </div>
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Data Science</p>
                        </div>
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Artificial Intelligence</p>
                        </div>
                      </div>
                      <div class="bt">
                        <router-link to="/">
                          <button>Learn More</button>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <img src="@/assets/images/FreePremiumStationeryMockup.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <div>
                        <h1>Tech4Teens Program</h1>
                        <p>
                          Tech4Teens is an innovative program designed to equip
                          teenagers with software development and IT skills.
                          Through hands-on training and practical experience,
                          teenagers can gain valuable knowledge in coding,
                          programming, and other technical skills that are in
                          high demand in today's job market. The program aims to
                          empower teenagers to become digital creators and
                          problem-solvers, giving them the tools they need to
                          succeed in the tech industry. Tech4Teens is an
                          excellent opportunity for teenagers who are interested
                          in pursuing a career in technology or simply want to
                          explore their interests in this exciting field.
                        </p>
                      </div>
                      <div class="tgs">
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Web development </p>
                        </div>
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Product Design</p>
                        </div>
                        <div class="ch">
                          <img src="@/assets/images/tagicon.svg" alt="" />
                          <p>Cybersecurity </p>
                        </div>
                      </div>
                      <div class="bt">
                        <router-link to="/">
                          <button>Learn More</button>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <img src="@/assets/images/FreePremiumStationeryMockup.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="reviews">
        <back-gradient top="-250px" right="-100px" />
        <back-gradient bottom="-250px" left="-100px" />
        <div class="container">
          <div
            id="reviewCarousel"
            class="carousel slide"
            data-ride="carousel"
            data-interval="4000"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-target="#reviewCarousel"
                data-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              >
                <span></span>
              </button>
              <button
                type="button"
                data-target="#reviewCarousel"
                data-slide-to="1"
                aria-label="Slide 2"
              >
                <span></span>
              </button>
              <button
                type="button"
                data-target="#reviewCarousel"
                data-slide-to="2"
                aria-label="Slide 3"
              >
                <span></span>
              </button>
              <button
                type="button"
                data-target="#reviewCarousel"
                data-slide-to="3"
                aria-label="Slide 4"
              >
                <span></span>
              </button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="w-100 mghold">
                        <div class="img">
                          <img
                            class="w-100"
                            src="@/assets/images/ayo.png"
                            alt=""
                          />
                        </div>
                        <h6>Iroko Ayomide Adeola</h6>
                        <p>UI/UX Trainee</p>
                      </div>
                    </div>
                    <!-- <div class="col-lg-1"></div> -->
                    <div class="col-lg-9">
                      <div class="rvhold">
                        <div class="review-text">
                          <h5>Mentee Review</h5>
                          <img src="@/assets/images/quote.svg" alt="" />

                          <p>
                            Before I got into mission2pro UI/UX training I had a
                            little knowledge and insight about what UI/UX means
                            and what it entails but when I started mission2pro I
                            knew what UI/UX means how to design and which app to
                            use. Most especially the videos which really helped
                            me a lot and I really appreciate. Thank You
                            Mission2pro for giving me the best experience ever.
                          </p>
                          <div class="inv">
                            <img src="@/assets/images/quote.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="w-100 mghold">
                        <div class="img">
                          <img
                            class="w-100"
                            src="@/assets/images/maleavatar.png"
                            alt=""
                          />
                        </div>
                        <h6>Adebayo Ayobami Quasim</h6>
                        <p>Data Analyst</p>
                      </div>
                    </div>
                    <!-- <div class="col-lg-1"></div> -->
                    <div class="col-lg-9">
                      <div class="rvhold">
                        <div class="review-text">
                          <h5>Mentee Review</h5>
                          <img src="@/assets/images/quote.svg" alt="" />

                          <p>
                            Before this program I've heard alot about data
                            analysis, but i dont know the path to take or which
                            trend to follow, signing up for this program Shown
                            me the way and process to follow to become a data
                            analyst. Today i can comfortably work with different
                            data analytics tools ranging from excel, PowerBi,
                            Tableau and can even work with SQL using different
                            dialect like SQLite, BigQuery, SQLSERVER.
                            <br />I have taken different professional courses
                            trending this path, I'm currently in the 6th course
                            of Google Data analytics professional certificate
                            course. All thanks to TeliyTech, Mission_2_Pro to me
                            has been an engine that pushed and energised my
                            journey in becoming a pro in data analytics. All
                            thanks to all the tutors and organisers, may God
                            bless you all.
                          </p>
                          <div class="inv">
                            <img src="@/assets/images/quote.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="w-100 mghold">
                        <div class="img">
                          <img
                            class="w-100"
                            src="@/assets/images/maleavatar.png"
                            alt=""
                          />
                        </div>
                        <h6>Ajadi Quadri Adedeji</h6>
                        <p>Mould Maintenance Specialist</p>
                      </div>
                    </div>
                    <!-- <div class="col-lg-1"></div> -->
                    <div class="col-lg-9">
                      <div class="rvhold">
                        <div class="review-text">
                          <h5>Mentee Review</h5>
                          <img src="@/assets/images/quote.svg" alt="" />

                          <p>
                            One of my mentors once said he will never do
                            anything worthwhile without having someone mentor
                            him on how to do it. In achieving my goal of
                            exploring data science, I am indeed fortunate to
                            have some great mentors from teliy tech streamline
                            the learning process. In the course of some months,
                            I have mastered the basics of excel and SQL for data
                            science as well as how to create visualizations
                            using powerbi. Looking forward to deepening my
                            understanding of data science. <br />
                            Thanks a lot teliy tech for the mission2pro program.
                          </p>
                          <div class="inv">
                            <img src="@/assets/images/quote.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="w-100 mghold">
                        <div class="img">
                          <img
                            class="w-100"
                            src="@/assets/images/ayo.png"
                            alt=""
                          />
                        </div>
                        <h6>Oduneye Olayinka</h6>
                        <p>UI/UX DESIGNER</p>
                      </div>
                    </div>
                    <!-- <div class="col-lg-1"></div> -->
                    <div class="col-lg-9">
                      <div class="rvhold">
                        <div class="review-text">
                          <h5>Mentee Review</h5>
                          <img src="@/assets/images/quote.svg" alt="" />

                          <p>
                            As a beginner in UI/UX DESIGNER with no experience
                            in designing, it wasn’t easy for me at first. <br />
                            Then I had the opportunity to join MISSION2PRO.
                            There I learned how to use Figma to design
                            applications for both mobile and desktop. I also
                            learned how to do wire-framing and prototyping and
                            also learned how to make my layout attractive and
                            understandable.
                          </p>
                          <div class="inv">
                            <img src="@/assets/images/quote.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </TransitionElement>
</template>

<script>
// @ is an alias to /src
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import TransitionElement from "@/components/Transition/TransitionElement.vue";
import BackGradient from "@/components/backGradient/BackGradient.vue";

gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

export default {
  name: "HomeView",
  components: {
    TransitionElement,
    BackGradient,
  },
  data() {
    return {
      scrold: false,
    };
  },
  computed: {
    screenWidth() {
      return screen.width;
    },
    isDesktop() {
      return this.screenWidth > 769;
    },
  },
  methods: {
    enter() {
      gsap.set(this.$refs.plane1cont, { scale: 0.7, autoAlpha: 1 });
      gsap.set(this.$refs.plane1, { transformOrigin: "50% 50%" });
      setTimeout(() => {
        gsap.to(this.$refs.plane1cont, {
          scrollTrigger: {
            trigger: this.$refs.hro,
            start: "top",
            end: "+=200",
            scrub: 1,
            // markers: true,
            onUpdate: (self) => {
              gsap.to(this.$refs.plane1, {
                rotation: () => (self.direction === 1 ? 0 : -180),
                overwrite: "auto",
              });
            },
          },
          duration: 10,
          ease: "none",
          immediateRender: true,
          motionPath: {
            path: this.$refs.path1,
            align: this.$refs.path1,
            alignOrigin: [0.5, 0.5],
            autoRotate: 90,
          },
        });
      }, 400);
      gsap.set(this.$refs.plane2cont, { scale: 0.7, autoAlpha: 1 });
      gsap.set(this.$refs.plane2, { transformOrigin: "50% 50%" });
      setTimeout(() => {
        gsap.to(this.$refs.plane2cont, {
          scrollTrigger: {
            trigger: this.screenWidth > 769 ? this.$refs.hro : this.$refs.path2,
            start: this.screenWidth > 769 ? "top -210" : "top 90%",
            end: this.screenWidth > 769 ? "+=200" : "bottom bottom",
            scrub: 1,
            // markers: true,
            onUpdate: (self) => {
              gsap.to(this.$refs.plane2, {
                rotation: () => (self.direction === 1 ? 0 : -180),
                overwrite: "auto",
              });
            },
          },
          duration: 10,
          ease: "none",
          immediateRender: true,
          motionPath: {
            path: this.$refs.path2,
            align: this.$refs.path2,
            alignOrigin: [0.5, 0.5],
            autoRotate: 90,
          },
        });
      }, 400);
      gsap.set(this.$refs.plane3cont, { scale: 0.7, autoAlpha: 1 });
      gsap.set(this.$refs.plane3, { transformOrigin: "50% 50%" });
      setTimeout(() => {
        gsap.to(this.$refs.plane3cont, {
          scrollTrigger: {
            trigger: this.screenWidth > 769 ? this.$refs.hro : this.$refs.path3,
            start: this.screenWidth > 769 ? "top -420" : "top 80%",
            end: this.screenWidth > 769 ? "+=250" : "bottom 95%",
            scrub: 1,
            // markers: true,
            onUpdate: (self) => {
              gsap.to(this.$refs.plane3, {
                rotation: () => (self.direction === 1 ? 0 : -180),
                overwrite: "auto",
              });
            },
          },
          duration: 10,
          ease: "none",
          immediateRender: true,
          motionPath: {
            path: this.$refs.path3,
            align: this.$refs.path3,
            alignOrigin: [0.5, 0.5],
            autoRotate: 90,
          },
        });
      }, 400);

      setInterval(
        () => {
          if (!this.scrold) {
            gsap.to(this.$refs.scr, {
              xPercent: this.isDesktop ? -5 : -50,
              duration: 1,
              onInterrupt: () => {
                console.log("interr1");
                gsap.set(this.$refs.scr, { xPercent: 0 });
              },
              onComplete: () => {
                gsap.to(this.$refs.scr, {
                  xPercent: 0,
                  duration: 1,
                  onInterrupt: () => {
                    console.log("interr2");
                    gsap.set(this.$refs.scr, { xPercent: 0 });
                  },
                });
              },
            });
          } else {
            // gsap.set(this.$refs.scr, { xPercent: 0 });
          }
        },
        this.isDesktop ? 3000 : 2500
      );
    },
    horScrol() {
      this.scrold = true;
    },
  },
  mounted() {
    // window.scrollTo(0, 0);
    // console.log("hiii");
  },
};
</script>
<style scoped lang="scss">
.mobh {
  @media (max-width: 769px) {
    overflow: hidden;
  }
}
section {
  padding: 10vh 0;
}
section.nopad {
  padding: 0;
}
svg {
  overflow: visible;
}
.sec2.re.nopad .container {
  width: 100%;
  height: auto;
  position: relative;
  overflow: visible;
  overflow-x: auto;
  padding: 8vh 0;
  padding-bottom: 52px;
  margin-bottom: 7vh;
  .hor {
    height: auto;
  }
  &::-webkit-scrollbar {
    /* display: none; */
    background-color: transparent;
    height: 5px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
  }
}
.hero {
  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 3.2rem;
    letter-spacing: 0em;
    text-align: left;
    width: 90%;
    @media (max-width: 769px) {
      margin-top: 52px;
      font-size: 2rem;
    }
  }
  p {
    margin-top: 24px;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    width: 80%;
  }
}
.spbu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div {
    width: 50%;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    div {
      width: 100%;
      /* margin-bottom: 54px; */
      .msvg {
        margin: 0;
        padding: 0;
        svg {
          width: 100%;
        }
      }
    }
  }
}
.spb {
  div {
    width: 50%;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    div {
      width: 100%;
      /* margin-bottom: 54px; */
    }
  }
}
.msvg {
  margin-left: -15%;
  padding: 0 16px;
  padding-top: 10%;
}
.cont {
  align-self: center;
  h1 {
    margin-bottom: 24px;
  }
}
.cen {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 769px) {
    /* margin-bottom: 54px; */
  }
}
.blbg {
  position: relative;
}
.ft {
  position: relative;
  display: flex;
  align-items: flex-end;
  div {
    margin-bottom: -24px;
    @media (max-width: 769px) {
      h1.w-100 {
        width: 250px !important;
      }
      margin-bottom: -32px;
    }
  }
  @media (max-width: 769px) {
    svg {
      margin-left: -125px;
    }
  }
}

.hor {
  /* width: 400%; */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  overscroll-behavior: none;
  .panel {
    width: 100%;
    margin-right: 25px;
    &:last-of-type {
      margin-right: 0;
    }
    /* width: 100%; */
  }
}
.re {
  position: relative;
}

.srv {
  position: relative;
  padding-top: 0;
  @media (max-width: 769px) {
    padding-top: 8vh;
  }
  h3 {
    color: var(--dtext);
  }
  span,
  p {
    font-size: 0.9rem;
    font-weight: 400;
  }
  span {
    color: var(--accent);
  }
  h1 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 3.2rem;
    /* letter-spacing: 0em; */
    text-align: left;
    width: 90%;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  button {
    background-color: var(--secondary);
    padding: 10px 24px;
    border: none;
    border-radius: 8px;
    color: var(--ntext);
    font-weight: 600;
    margin-top: 54px;
    @media (max-width: 769px) {
      margin-bottom: 52px;
    }
  }
}
.m2pro {
  /* padding: 2vh, 0; */
  width: 100%;
  background-color: var(--secondary20);
  h1 {
    margin-bottom: 24px;
  }
  .col-md-4 {
    img {
      width: 100%;
    }
  }
  .carousel {
    .carousel-indicators {
      bottom: -47px;
      margin-bottom: 0;
      button {
        background-color: #01bfff80;
        width: 13px;
        height: 13px;
        border: none;
        margin-right: 5px;
        margin-left: 5px;
        border-radius: 50%;
        &.active {
          background-color: var(--accent);
        }
      }
    }
  }
  .tgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 64px;
    * {
      margin: 0;
    }
    .ch {
      display: flex;
      align-items: center;
      p {
        color: var(--accent);
        font-size: 0.9rem;
        font-weight: 400;
      }
      img {
        margin-right: 5px;
      }
    }
    @media (max-width: 769px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      .ch {
        margin-top: 12px;
      }
    }
  }
  .bt {
    button {
      background-color: var(--secondary);
      padding: 10px 24px;
      border: none;
      border-radius: 8px;
      color: var(--ntext);
      font-weight: 600;
      @media (max-width: 769px) {
        margin-bottom: 52px;
      }
    }
  }
}

.reviews {
  /* .row{
    align-items: stretch;
  } */
  position: relative;
  .container {
    .carousel {
      padding: 0 4rem;
      @media (max-width: 769px) {
        padding: 0;
      }
      .carousel-indicators {
        bottom: -47px;
        margin-bottom: 0;
        button {
          background-color: #01bfff80;
          width: 13px;
          height: 13px;
          border: none;
          margin-right: 5px;
          margin-left: 5px;
          border-radius: 50%;
          &.active {
            background-color: var(--accent);
          }
        }
      }
    }
  }
  @media (max-width: 769px) {
    .col-lg-3 {
      margin-bottom: 24px;
    }
  }
  .mghold {
    background-color: #1277dd33;
    padding: 1.7rem 1.5rem;
    border-radius: 20px;
    height: 100%;
    .img {
      position: relative;
      width: 100%;
      padding-top: 88%;
      border-radius: 20px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    h6 {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 600;
      margin-top: 24px;
      margin-bottom: 8px;
    }
    p {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 400;
      margin: 0;
    }
  }
  .rvhold {
    background-color: #1277dd33;
    padding: 3rem 1.5rem;
    height: 100%;
    border-radius: 20px;
    width: 100%;
    .inv {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        transform: rotate(180deg);
      }
    }
  }
}

@media (max-width: 769px) {
  h1 {
    /* margin-top: 32px; */
    font-size: 2rem !important;
  }
  .srv h3 {
    font-size: 1.4rem;
  }
  p {
    font-size: 0.8rem !important;
  }
}
</style>
