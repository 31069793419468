<template>
  <div class="w-100">
    <back-gradient top="-150px" right="-150px" />
    <header>
      <transition-nav v-if="openNav" :slow="loading">
        <div ref="nav" class="realnav" @click="toggleNav">
          <div class="container">
            <back-gradient top="-250px" left="-250px" />
            <div class="str">
              <div class="clbtn">
                <div>CLOSE <span>&#10006;</span></div>
              </div>
              <div class="row navcontent">
                <div class="col-lg-3 menn">
                  <h2>MENU</h2>
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-5 nacon">
                  <div class="w-100">
                    <div class="bnvtm">
                      <router-link to="/works">
                        <h3>Our Works</h3>
                        <div class="navitm">
                          <div>
                            <p>See previous projects</p>
                          </div>
                          <img src="@/assets/images/rightarr.svg" alt="" />
                        </div>
                      </router-link>
                    </div>
                    <div class="bnvtm">
                      <router-link to="/about">
                        <h3>Know Us Better</h3>
                        <div class="navitm">
                          <div>
                            <p>About Us</p>
                          </div>
                          <img src="@/assets/images/rightarr.svg" alt="" />
                        </div>
                      </router-link>
                    </div>
                    <div class="bnvtm">
                      <router-link to="/contact">
                        <h3>Join Our Team</h3>
                        <div class="navitm">
                          <div>
                            <p>Vacancies</p>
                          </div>
                          <img src="@/assets/images/rightarr.svg" alt="" />
                        </div>
                      </router-link>
                    </div>
                    <div class="bnvtm">
                      <router-link to="/training">
                        <h3>Training Program</h3>
                        <div class="navitm">
                          <div>
                            <p>Learning Program</p>
                          </div>
                          <img src="@/assets/images/rightarr.svg" alt="" />
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="col-lg-1"></div>
                <div class="col-lg-2">
                  <img
                    src="@/assets/images/opennavlogo.svg"
                    class="w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-nav>
      <transition-nav v-else :slow="loading">
        <nav ref="nav" class="container">
          <div class="w-100 navdiv">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/images/logo.svg" alt="" />
              </router-link>
            </div>
            <div class="menu" @click="toggleNav">
              <div>MENU</div>
              <div class="ham">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </nav>
      </transition-nav>
    </header>
    <main v-if="!home" ref="anm">
      <router-view />
    </main>
    <router-view v-else />
    <footer>
      <div class="container" ref="ft">
        <div class="up">
          <div class="row w-100">
            <div class="col-md-4">
              <div class="w-100">
                <h1>Get In <br />Touch</h1>
              </div>
            </div>
            <div class="col-md-4">
              <div class="w-100">
                <div class="bnvtm">
                  <router-link to="/contact">
                    <h3>Work With Us</h3>
                    <div class="navitm">
                      <div>
                        <p>Contact Us</p>
                      </div>
                      <img src="@/assets/images/rightarr.svg" alt="" />
                    </div>
                  </router-link>
                </div>
                <div class="bnvtm">
                  <router-link to="/about">
                    <h3>Know Us Better</h3>
                    <div class="navitm">
                      <div>
                        <p>About Us</p>
                      </div>
                      <img src="@/assets/images/rightarr.svg" alt="" />
                    </div>
                  </router-link>
                </div>
                <div class="bnvtm">
                  <router-link to="/contact">
                    <h3>Join Our Team</h3>
                    <div class="navitm">
                      <div>
                        <p>Vacancies</p>
                      </div>
                      <img src="@/assets/images/rightarr.svg" alt="" />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="w-100">
                <div class="bnvtm">
                  <router-link to="/training">
                    <h3>Training Program</h3>
                    <div class="navitm">
                      <div>
                        <p>Learn With Us</p>
                      </div>
                      <img src="@/assets/images/rightarr.svg" alt="" />
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dn">
          <div class="w-100 row align-items-center">
            <div class="col-md-4">
              <div class="w-100">
                <img src="@/assets/images/footlogo.svg" alt="" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="w-100">
                <p class="copy">
                  &copy; {{ thisYear }} Teliytech All rights reserved.
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="w-100">
                <router-link to="/">Privacy Policy</router-link>
                <router-link to="/">Data Privacy</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import gsap from "gsap";
import BackGradient from "./components/backGradient/BackGradient.vue";
import TransitionNav from "./components/Transition/TransitionNav.vue";
import TransitionElement from "./components/Transition/TransitionElement.vue";

export default {
  data() {
    return {
      openNav: false,
      loading: true,
      home: true,
    };
  },
  computed: {
    thisYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    toggleNav() {
      gsap.to(this.$refs.nav, {
        y: -300,
        duration: 0.3,
        ease: "out",
        opacity: 0,
        onComplete: () => {
          this.openNav = !this.openNav;
        },
      });
    },
  },
  watch: {
    $route(to, from) {
      // gsap.set(this.$refs.anm, { opacity: 1, x: -200 });
      // console.log(this.$route);
      if (this.$route.name === "home") {
        this.home = true;
      } else {
        this.home = false;
      }
      gsap.from(this.$refs.anm, {
        x: -200,
        duration: 0.6,
        autoAlpha: 0,
      });
      gsap.from(this.$refs.ft, {
        x: -200,
        duration: 0.6,
        autoAlpha: 0,
        delay: 0.4,
      });
    },
  },
  components: { BackGradient, TransitionNav, TransitionElement },
  mounted() {
    // onBeforeRouteLeave((to, from, next) => {
    //   gsap.to(this.$refs.anm, {
    //     x: 200,
    //     duration: 0.4,
    //     opacity: 0,
    //     onComplete: () => {
    //       next();
    //     },
    //   });
    // });
    setTimeout(() => {
      this.loading = false;
    }, 800);
  },
};
</script>
<style lang='scss' >
* {
  margin: 0;
  padding: 0;
}
:root {
  --primary: #04042b;
  --secondary: #0047ab;
  --secondary20: #0047ab33;
  --accent: #01bfff;
  --waccent: #1277dd;
  --waccent30: #1277dd4d;
  --ntext: #ffffff;
  --text: #ffffffcc;
  --darktext: #1a1a1acc;
  --dtext: #ffffff99;
  --gray: #484848cc;
  --plchldr: #5f5f5f;
  --lightgray: #f6f6f6;
}
html {
  overflow: hidden;
  overflow-y: auto;
  /* background-color: var(--primary); */
}
body {
  overscroll-behavior: none;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
#app {
  font-family: "Outfit", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text);
  position: relative;
}
a {
  text-decoration: none;
}
.spb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* nav a.router-link-exact-active {
  color: #42b983;
} */
.ovfh {
  overflow: hidden;
  position: relative;
}
</style>
<style scoped lang="scss" >
.realnav {
  background-color: var(--secondary);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  @media (max-width: 769px) {
    height: 100vh;
  }
  .container {
    position: relative;
  }
}
header {
  position: relative;
}
.navdiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  background-color: transparent;
  * {
    background-color: transparent;
  }
}
.navcontent {
  padding-bottom: 54px;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 13px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    color: var(--secondary);

    .ham {
      span {
        background-color: var(--secondary);
      }
    }
  }
  .ham {
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 12px;
    height: 100%;
    span {
      display: block;
      transition: all 0.3s ease-out;
      background-color: var(--text);
      width: 100%;
      height: 3px;
      /* margin-bottom: 3px; */
    }
  }
}
.main {
  position: relative;
}
.bnvtm {
  margin-bottom: 32px;
  position: relative;
  a {
    color: var(--text);
    font-size: 0.85rem;
    font-weight: 400;
    text-align: left;
  }
}
.navitm {
  display: flex;
  align-items: flex-end;
  * {
    margin: 0;
  }
  img {
    margin-left: 16px;
    height: 18px;
    margin-bottom: 1px;
  }
}
.clbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0 54px;
  div {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    span {
      color: var(--text);
    }
  }
  span {
    margin-left: 5px;
    display: inline-block;
    color: var(--text);
  }
}

footer {
  background-color: var(--secondary);
  padding: 52px 0 16px 0;
  h1 {
    font-size: 3.3rem;
    font-weight: 700;
    /* line-height: 81px; */
    letter-spacing: 0em;
    text-align: left;
  }
  .row {
    margin: 0;
  }
  .dn {
    /* margin-top: 54px; */
    * {
      margin: 0;
    }
    a {
      color: var(--text);
      font-size: 0.85rem;
      font-weight: 400;
      margin-right: 14px;
      display: inline-block;
    }
    @media (max-width: 769px) {
      .w-100 {
        .w-100 {
          margin-top: 12px;
        }
      }
    }
  }
}
.str {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  .clbtn {
    width: 100%;
  }
  .navcontent {
    width: 100%;
  }
  @media (max-width: 991px) {
    flex-direction: row-reverse;
    padding-top: 24px;
    .clbtn {
      padding: 0;
    }
    .menn {
      margin-bottom: 24px;
    }
    .nacon {
      margin-bottom: 24px;
    }
  }
}
</style>
