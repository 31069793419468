<template>
  <transition
    appear
    :css="false"
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
    ref="cont"
  >
    <slot></slot>
  </transition>
</template>

<script>
import gsap from "gsap";

export default {
  props: ["slow"],
  methods: {
    beforeEnter(el) {
      gsap.from(el, {
        y: -200,
        duration: this.slow ? 1 : 0.3,
        autoAlpha: 0,
        ease: "out",
        delay: this.slow ? 0.3 : 0,
      });
    },
    enter() {
      // console.log("enter");
    },
    leave() {
      // console.log("leave");
    },
  },
  // mounted() {
  //   onBeforeRouteLeave((to, from, next) => {
  //     gsap.to(this.$refs.cont, {
  //       x: 200,
  //       duration: 0.4,
  //       opacity: 0,
  //       onComplete: () => {
  //         next();
  //       },
  //     });
  //   });
  // },
};
</script>

<style>
</style>