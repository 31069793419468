<template>
  <transition
    :css="false"
    appear
    @before-enter="beforeEnter"
    @enter="enter"
    @leave="leave"
    ref="cont"
  >
    <slot></slot>
  </transition>
</template>

<script>
import gsap from "gsap";
import { onBeforeRouteLeave } from "vue-router";

export default {
  methods: {
    beforeEnter(el) {
      gsap.from(el, {
        x: -200,
        duration: 1,
        autoAlpha: 0,
        delay: 0.3,
      });
    },
    enter() {
      // console.log("enter");
    },
    leave() {
      // console.log("leave");
    },
  },
  mounted() {
    onBeforeRouteLeave((to, from, next) => {
      gsap.to(this.$refs.cont, {
        x: 200,
        duration: 0.4,
        opacity: 0,
        onComplete: () => {
          next();
        },
      });
    });
  },
  watch: {
    
  },
};
</script>

<style>
</style>